<template>
  <div>
    <div class="mb-4 text-lg black95 bg-white py-8 border-b border-t px-4">汇兑记录</div>
    <div class="sm:p-12  p-2 bg-white">
      <!-- <div class="mb-4 text-lg black95">汇兑记录</div> -->
      <div class=" flex justify-end">
        <a-button  type="primary" icon="transaction" class="editable-add-btn" @click="handleRouteConvert">
          兑换
        </a-button>
      </div>
      <a-table class="mt-4 overflow-x-auto" :loading="dataLoding" bordered :data-source="dataSource" :columns="columns" rowKey="id"
      :pagination="false"
      >
        <!-- <template slot="name" slot-scope="text, record">
          <EditableCell :text="text" @change="onCellChange(record.key, 'name', $event)" />
        </template> -->
        <template slot="sellCurrency" slot-scope="text,record">
          <div class="flex items-center">
            <span class=" font-600 black95">
              {{ record.client_after_sell_amount }} {{ record.sell_currency }}</span>
          </div>
        </template>

        <template slot="buyCurrency" slot-scope="text,record">
          <div class="flex items-center">
            <span class=" font-600 black95">
              {{ record.client_buy_amount }} {{ record.buy_currency }}</span>
          </div>
        </template>

        <template slot="status" slot-scope="text,record">
          <div class="flex items-center">
            <a-tag
              :color="statusHandle(record.status).color"
                  >
                {{ statusHandle(record.status).text }}
              </a-tag>
          </div>
        </template>
        <template slot="settlementDate" slot-scope="text">
          <span>
            {{ formatedDate(text.settlement_date) }}
          </span>
        </template>
      </a-table>
      <div class=" mt-6 flex  justify-end">
        <a-pagination
        :current="currentPage"
        :total="total_entry"
        :pageSize="25"
        @change="pageOnchange" :defaultCurrent="1" />
      </div>
    </div>
  </div>
</template>
<script>
import { getCCConvertList } from '@/api/common'
import { mapState } from 'vuex'

export default {
  created () {
    this.fetchData(this.currentPage)
  },
  data () {
    return {
      currentPage: 1,
      total_entry: 25,
      dataLoding: false,
      dataSource: [
      ],
      count: 2,
      columns: [
        {
          key: 'reference',
          title: '交易编号',
          dataIndex: 'short_reference'
        },
        {
          key: 'direction',
          title: '汇率方向',
          dataIndex: 'fix_side_currency_pair'
        },
        {
          key: 'Sell',
          title: '卖(币种)',
          dataIndex: 'buy_currency',
          scopedSlots: { customRender: 'sellCurrency' }
        },
        {
          key: 'rate',
          title: '汇率',
          dataIndex: 'client_after_rate'
        },
        {
          key: 'Buy',
          title: '买(币种)',
          dataIndex: 'buy_currency',
          scopedSlots: { customRender: 'buyCurrency' }
        },
        {
          key: 'settlement_date',
          title: '结算日期(NZ)',
          scopedSlots: { customRender: 'settlementDate' }
        },
        {
          key: 'status',
          title: '状态',
          scopedSlots: { customRender: 'status' }
        }
      ]
    }
  },
  beforeRouteEnter (to, from, next) {
    next()
    // next(vm => {
    //   if (!vm.isCCactive) {
    //     vm.$message.error('Please activate your account first')
    //     next({ name: 'UserDash' })
    //   }
    // })
  },

  methods: {
    fetchData (page) {
      this.dataLoding = true
      getCCConvertList({ customer_id: this.customer.id, page }).then(res => {
        this.dataSource = res.conversions
        if (res?.pagination?.total_entries) {
          this.total_entry = res.pagination.total_entries
        }
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.dataLoding = false
      })
    },
    pageOnchange (val) {
      this.currentPage = val
      this.fetchData(this.currentPage)
    },
    formatedDate (val) {
      const date = new Date(val)
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      }
      const formatter = new Intl.DateTimeFormat('zh-CN', options)
      return formatter.format(date)
    },
    statusHandle (stauts) {
      if (stauts === 'awaiting_funds') {
        return { text: 'waiting for funds', color: 'geekblue' }
      } else if (stauts === 'pending_transactions') { return { text: 'pending transactions', color: 'geekblue' } } else if (stauts === 'failed_payments') { return { text: 'failed payment', color: 'volcano' } } else {
        return { text: 'completed', color: 'green' }
      }
    },
    handleRouteConvert () {
      this.$router.push({ name: 'Convert' })
    }
  },
  computed: {
    ...mapState('cc', ['customer'])
  }
}
</script>
<style>
.editable-cell {
  position: relative;
}

.editable-cell-input-wrapper,
.editable-cell-text-wrapper {
  padding-right: 24px;
}

.editable-cell-text-wrapper {
  padding: 5px 24px 5px 5px;
}

.editable-cell-icon,
.editable-cell-icon-check {
  position: absolute;
  right: 0;
  width: 20px;
  cursor: pointer;
}

.editable-cell-icon {
  line-height: 18px;
  display: none;
}

.editable-cell-icon-check {
  line-height: 28px;
}

.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}

.editable-cell-icon:hover,
.editable-cell-icon-check:hover {
  color: #108ee9;
}

.editable-add-btn {
  margin-bottom: 8px;
}
</style>
